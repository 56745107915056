.header {
  width: 100%;
  height: 7rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  background-color: #0c008d;
  padding: 0 8% 0 9%;
}

.header span {
  text-align: right;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
  color: white;
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fcb8d2;
}